.App {
  text-align: center;
}

body {
  background-color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.barcodeImage {
  width: 100px;
  float: right;
  border: 2px solid #164396;
}

p {
  margin-bottom: 0px;
  margin-top: 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Style the header with a grey background and some padding */
.header {
  overflow: hidden;
  background-color: #f1f1f1;
  padding: 20px 10px;
}

/* Style the header links */
.header a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px;
  line-height: 25px;
  border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
  font-size: 25px;
  font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the active/current link*/
.header a.active {
  background-color: dodgerblue;
  color: white;
}

/* Float the link section to the right */
.header-right {
  float: right;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(70, 72, 80);
  color: white;
  text-align: center;
}

.dashboard-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  zoom: 78%;
}

.dashboard-sub-1 {
  width: calc(100% - 500px);
  padding: 0px 0px 20px 100px;
}
.mainImage {
  background-image: url("../images/Teams-BG_Option-1_1.png");
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-size: 100%;
  max-height: 600px;
  border: 1px ridge #ccc;
  background-color: #fff;
  background-position: center;
}
.dashboard-sub-2 {
  width: calc(50% - 20px);
  text-align: center;
  padding: 25px;
  background-color: #fff;
  min-height: 600px;
  margin-right: 100px;
}

.profileCard .card {
  height: auto;
}

.profileCard .card .div-1 {
  width: 100%;
  height: 258px;
  background-size: cover;
  background-position: 78%;
  padding-top: 78px;
  margin-bottom: 0px;
}

.barcodeBlock.col-md-6 img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  border: #164396 solid 2px;
}

.barcodeBlock {
  padding: 20px;
}

.qrBtn button {
  width: 100%;
}

.copyUrl {
  padding: 10px;
  width: 100%;
}

.copyUrl input {
  width: 100%;
}

.bg-primary {
  background-color: rgb(70, 72, 80) !important;
}

.btn-primary {
  background-color: #486aae !important;
  border-color: #486aae !important;
}

.dashboard-sub-2 p {
  font-size: 22px;
  text-align: left;
  margin-top: 20px;
  font-weight: bold;
  color: grey;
}
#userTitle {
  text-align: left;
  font-size: 1.8rem;
}

.headGray {
  color: gray;
}
.button {
  background-color: #486aae;
  border-color: #486aae;
  color: white;
  border-radius: 16px;
  padding: 10px 20px;
}

.footer-links a {
  color: #ccc;
  font-size: 15px;
  padding: 5px;
}

#username {
  color: #486aae;
  font-weight: bold;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 900px) {
  .dashboard-sub-1 {
    width: 100%;
    text-align: center;
    height: 193px;
    padding: 0;
  }
  .dashboard-sub-2 {
    width: 100%;
    text-align: center;
    margin-top: 0px;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
  background-color: white;
  text-transform: capitalize;
  padding: 0px 16px 16px 16px;
}

.logo {
  float: right;
}

.title {
  color: #05509f;
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  margin-top: -50px;
  /* padding-left: 40px; */
  /* padding-right: 40px;*/
}

.subTitle {
  color: black;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
}

.subTitle1 {
  color: black;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  margin-top: -15px;
}
.email {
  text-transform: lowercase;
}

.paddLR40 {
  padding-right: 40px;
  padding-left: 40px;
}

/* .profile button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
} */

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}

.iconMainDiv {
  padding: 30px;
  display: flex;
  justify-content: space-around;
}

.div-1 {
  background-image: url("../images/Spectrum.svg");
  width: 100%;
  height: 250px;
  background-size: cover;
  background-position: 76%;
  padding-top: 76px;
  margin-bottom: 40px;
}

.secondName {
  margin-top: 0px;
}

.addAccount {
  width: 12%;
  height: 50px;
  float: right;
  top: 63%;
  position: absolute;
  z-index: 1;
  right: 3%;
}

.addAccount img {
  width: 100%;
  height: 36px;
  float: right;
}

.topLogo {
  width: 35%;
  height: 50px;
  float: right;
  padding-top: 10px;
  margin-right: 0px;
}

.companyLogo {
  width: 15%;
  /* padding: 6px; */
  height: 20px;
}

.xlAxiataLogo {
  width: 74px;
  height: 47px;
  margin-right: 10px;
}

.linkNetLogo {
  margin-bottom: 5px;
  margin-right: 10px;
  width: 58px;
}

.dialogLogo {
  margin-bottom: 9px;
  height: 50px;
  width: 47px;
}

.secondRowIcon {
  margin-top: -30px;
  width: 60px;
  height: 26px;
}

.boostLogo {
  width: 43px;
  height: 25px;
  margin-right: 5px;
}

.adaLogo {
  height: 25px;
  width: 28px;
  margin-right: 5px;
}

.digitalLabsLogo {
  width: 40px;
  margin-right: 5px;
}

.edotco {
  width: 66px;
}

.footerCss {
  font-size: 14px;
  text-transform: lowercase;
  padding: 0;
  color: #05509f;
  font-weight: bold;
  margin-top: 15px;
}

.FooterLogos {
  min-width: 205px;
}

/* Add media queries for responsiveness - when the screen is 500px wide or less, stack the links on top of each other */
@media screen and (max-width: 500px) {
  .profileCard .card .div-1 {
    background-position: 65%;
  }
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
  .dashboard-main {
    flex-direction: column;
  }
  .dashboard-sub-1 {
    width: 100%;
    text-align: center;
    height: 193px;
    padding: 0;
  }
  .mainImage {
    background-position: inherit;
  }
  .dashboard-sub-2 {
    width: 100%;
    text-align: center;
    margin-top: 0px;
  }

  .card {
    max-width: 100% !important;
    height: 99vh;
    overflow: hidden;
    padding: 0px 30px 15px 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .div-1 {
    height: 50%;
    background-size: cover;
    background-position: 70%;
    padding-top: 38%;
    margin-bottom: 0px;
    width: none;
    padding-right: 20px;
  }

  .topLogo {
    height: 65px;
    width: auto;
  }

  .FooterLogos {
    position: relative;
    bottom: 15px;
    margin-top: 25px;
  }
}
@media screen and (max-width: 425px) {
  .div-1 {
    padding-top: 48%;
  }
}

@media only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) {
  .div-1 {
    padding-top: 34%;
  }
}

@media screen and (max-width: 395px) {
  .div-1 {
    height: 50%;
    background-size: cover;
    background-position: 68%;
    padding-top: 48%;
    margin-bottom: 0;
    width: none;
    padding-right: 15px;
  }
}

@media screen and (max-width: 380px) {
  .div-1 {
    height: 50%;
    background-size: cover;
    background-position: 68%;
    padding-top: 36%;
    margin-bottom: 0;
    width: none;
    padding-right: 5px;
  }
}
@media screen and (max-width: 365px) {
  .div-1 {
    padding-top: 44%;
  }
}
@media only screen and (device-width: 360px) and (device-height: 720px) and (-webkit-device-pixel-ratio: 3) {
  .div-1 {
    padding-top: 34%;
  }
}
